<template>
  <side-content>
    <template v-slot:header>
      Timeline
      <v-spacer />
      <v-btn
        class="ml-2"
        color="#E5E5EA"
        fab
        depressed
        x-small
        :to="{ name: 'CaseShow' }">
        <v-icon color="primary">
          close
        </v-icon>
      </v-btn>
    </template>

    <loading v-if="loading > 0" class="absolute-center" />

    <template v-for="([date, msgs], i) in timeline" v-else>
      <h2
        :key="date"
        class="body-1 font-weight-bold text-center black--text pt-2 pb-1">
        {{ date }}
      </h2>
      <v-list :key="'timeline-'+i" flat dense>
        <template v-for="(item, i) in msgs">
          <v-list-item :key="i">
            <v-list-item-icon>
              <font-awesome-icon v-if="isProposal(item)" color="primary" :icon="setIcon(item.event)" />
              <v-icon v-else color="primary" v-text="setIcon(item.event)" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold" v-text="item.time" />
              <v-list-item-subtitle>{{ $t('timeline.state.' + item.event, item.params) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="i < (msgs.length - 1)" :key="'divider-'+i" />
        </template>
      </v-list>
    </template>
  </side-content>
</template>

<script>
  import casesMixin from '@/mixins/cases'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ casesMixin ],
    data () {
      return {
        options: { suppressScrollX: true, wheelPropagation: false }
      }
    },
    methods: {
      isProposal (item) {
        return item.event === 'proposal_created' ||
          item.event === 'proposal_visualized'
      },
      setIcon (status) {
        return this.$root.icons.timeline[status] ||
          this.$root.icons.timeline.others
      }
    },
    computed: {
      ...mapGetters({
        timeline: 'cases/timeline',
        loading: 'cases/timelineLoading'
      })
    },
    beforeMount () {
      this.$store.dispatch('cases/getTimeline', this.$route.params.caseId)
    }
  }
</script>

<style lang="sass">
  .timeline
    height: 100%
    transform: translateX(0)

    .ps
      height: calc(100vh - 100px)
</style>
